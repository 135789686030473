
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import { $enum } from 'ts-enum-util';
import CAR_PRICE_TYPE from '@/modules/cars/constants/car-price-type.constant';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';

@Component({
    components: { CustomSelect },
})
export default class PriceTypeFilter extends Vue {
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @Inject(FleetServiceS) private fleetService!: FleetService;

    get items(): Item[] {
        return $enum(CAR_PRICE_TYPE).map((value): Item => ({
            value,
            name: this.$t(`cars.priceType.${value}`) as string,
        }));
    }

    get currentValue() {
        return this.fleetService.storeState.settings.priceType;
    }
    set currentValue(value) {
        if (value) {
            this.fleetService.storeState.settings.priceType = value;
        }
    }
}
