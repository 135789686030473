
import { Component, Vue, Prop } from 'vue-property-decorator';
import type Day from '@/modules/common/types/day.type';
import isDayType from '@/modules/common/component-validators/is-date.validator';
import EventTypesPoints from '@/modules/common/components/event-types-points.vue';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import type { IResponsiveStyle } from '@/modules/cars/modules/fleet/fleet-settings.types';

@Component({
    components: {
        EventTypesPoints,
    },
})
export default class FleetCalendarItemSoldOut extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;

    @Prop({
        required: true,
        type: Number,
        validator: isDayType,
    })
    private day!: Day;

    @Prop({
        required: true,
        type: String,
    })
    private source!: string;

    @Prop({
        required: true,
    })
    private styles!: IResponsiveStyle;

    get month(): Month {
        return this.documentFiltersService.storeState.settings.month;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    get eventsData() {
        if (!this.day) {
            return {};
        }

        const data: any = {};
        data[this.day] = {
            local: false,
            holiday: false,
        };
        data[this.day].local = this.localEvents;
        data[this.day].holiday = this.hasHolidayEvents;

        return data;
    }

    get localEvents(): boolean {
        const myEvents = this.eventsManagerService.getMyEventsByDay({ day: this.day as Day });
        const marketEvents = this.eventsManagerService.getMarketEventsByDay({ day: this.day as Day });
        const chainEvents = this.eventsManagerService.getChainEventsByDay({ day: this.day as Day });
        return Boolean(myEvents.length || marketEvents.length || chainEvents.length);
    }

    get hasHolidayEvents() : boolean {
        return Boolean(this.eventsManagerService.getCountryEventsByDay({ day: this.day, month: this.month, year: this.year }).length);
    }
}
